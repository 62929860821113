.btn-plus-tm {
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #E2E7F2;
    width: 40px;
    height: 40px;
    border-radius: 12px;
    cursor: pointer;
}

.btn-edit-tm {
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    padding: 9px 15px;
    margin-top: 20px;
    background-color: #E2E7F2;
    >span {
        color: #5C6578;
        font-size: 14px;
        margin-left: 8px;
    }
}

.template-message-list-container {
  max-height: calc(100vh - 140px);

  @media (max-width: 992px) {
    max-height: calc(100vh - 131px);
    height: 100%;
  }
}