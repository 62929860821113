.contacts__tags {
  margin-bottom: 30px;
}

.listLote__bodyModal {
  > .itemLote__bodyModal {
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0px;
    }
  }
}

.contacts__phoneStatus {
  display: flex;
  align-items: center;
}

.contacts__phoneStatus__divider {
  display: flex;
  //   border-left: 1px solid black;
  margin-left: 7px;
  margin-right: 7px;
  //   height: 12px;
  color: black;
  margin-top: -3px;
}

.contacts__phoneStatus__text__notWhatsapp {
  //   color: #ff4757;
  color: white;
  background-color: #ff4757;
  padding: 1px 8px;
  border-radius: 4px;
}

.contacts__phoneStatus__text__notOptedIn {
  //   color: #ff7f50;
  color: white;
  background-color: #ff7f50;
  padding: 1px 8px;
  border-radius: 4px;
}

.contacts__header__group {
  // display: grid;
  // grid-template-columns: 1fr 0.15fr;
  // column-gap: 10px;
  display: flex;
  flex-direction: column;
  > div {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0px;
    }
  }
}

.contacts__btn__iconFilter {
  background-color: transparent;
  border: none;
}

.contacts__btn__iconClose {
  background-color: transparent;
  border: none;
  > svg {
    width: 20px;
    height: 20px;
  }
}

.contact__status__svg {
  margin-bottom: 3px;
  margin-right: 8px;
  color: #7269ef;
}

.z-index-auto {
  z-index: auto;
}
