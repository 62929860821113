.templates {
    background: #fff;
    height: 100vh;
    box-shadow: 0 2px 4px rgba(15, 34, 58, 0.12);
    z-index: 100;
    flex: 1;
    min-height: 100vh;
    overflow: hidden;
    padding: 1rem;
    min-width: 380px;
    max-width: 400px;
    right: 0;
    top: 0;
    position: fixed;
    @media screen and (max-width: 991.98px) {
        right: 0;
        left: 0;
        top: 0;
        position: fixed;
        width: 100%;
    }

    &__header {
        padding: 1rem;
    }

    &__item {
        padding: 1rem;
        cursor: pointer;
        button {
            opacity: 0;
            color: #7269ef !important;
        }
        &:hover {
            background-color: #e6ebf5;      
        }
        &:hover button {
            opacity: 1;
        }
    }
    &__text {
        word-break: break-all;
        overflow: hidden;
    }
}

.tmessages-remove-btn {
  background-color: rgb(255, 222, 222);
  color: #FF6060;
}

.tmessages-file-container {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  max-height: 300px;
  margin: auto;
  font-size: 0.9375rem;
}

.tmessages-image {
  width: auto;
  max-width: 450px;
  height: auto;
  max-height: 200px;
  min-height: 120px;
  background-color: #F7F7F7;
  margin: 5px auto 25px;
  object-fit: contain;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.tmessages-video {
  width: auto;
  max-width: 450px;
  height: auto;
  max-height: 200px;
  min-height: 120px;
  background-color: #F7F7F7;
  margin: 5px auto 25px;
  object-fit: contain;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.display-items-start {
  display: flex;
  justify-content: start;
  align-items: center;
}

.display-items-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tag-options-container {
  display: block;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  max-width: 100%;
  border: 1px solid #e6ebf5;
  border-radius: 0.25rem;
  font-size: 0.8rem;
  padding-bottom: 8px;
  padding-right: 8px;
}

.tag-div {
  display: block;
  float: left;
  height: 25px;
  background-color: #E5E5E5;
  border-radius: 2px;
  margin-top: 8px;
  margin-left: 8px;
}

.tag-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tag-close-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border-radius: 2px;
  height: 25px;
  border: none;
  cursor: pointer;
  color: #495057;
}

.tag-close-button:hover {
  background-color: #FFBDAD;
  color: #DE350B;
}

.list-item-circle li {
  list-style-type: circle;
}