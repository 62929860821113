.chatTopHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    >h5 {
        margin-bottom: 0px !important;
    }
}

.listFilterChat {
    display: flex;
    align-items: center;
    >.listFilterChat__item {
        margin-right: 5px;
        &:last-child{
            margin-right: 0px;
        }
    }
}

.listFilterChat__item {
    display: inline-block;
    border-radius: 10px;
    padding: 3px 8px 4px;
    cursor: pointer;
    margin-bottom: 0px;
    >input[type="radio"]{
        display: none;
    }
}

.listFilterChat__item--check {
    background-color: #ff7f50 !important;
    >span {
        color: #ffffff !important;
    }
}