// 
// authentication.scss
//

.auth-logo{
    .logo{
        margin: 0px auto;
    }

    .logo-dark{
        display: $display-block;
    }

    .logo-light{
        display: $display-none;
    }
}

.blur-screen {
    flex-flow: column;
    backdrop-filter: blur(3px);
    background-color: rgba(0, 0, 0, 0.4);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    font-size: 17px;
    font-weight: 600;
    color: white;
    text-align: center;
}
