.loadingLocked {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1051;
  background-color: #00000015;
  bottom: 0;
}

.loadingLocked__body {
    // background-color: white;
    border-radius: 35px;
    padding: 100px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.loadingLocked__blur {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: #00000055;
    top: 0;
    left: 0;
    z-index: -1;
    filter: blur(100px);
}

.loadingLocked__loader {
  animation: spin 1s infinite linear;
//   border: 2px solid rgba(30, 30, 30, 0.5);
  border: 2px solid #57606f;
  border-left: 4px solid #fff;
  border-radius: 50%;
  height: 50px;
  margin-bottom: 10px;
  width: 50px;
}

@keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

.loadingLocked__text {
    margin: 0;
    margin-top: 10px;
    text-align: center;
    font-weight: bold;
    color: white;
}

.loadingLocked__modal__blur {
    filter: blur(2px);
}