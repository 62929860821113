.not-show-mobile-sidebar-menu
    display: none

@media (min-width: 992px)
    .not-show-mobile-sidebar-menu
        display: initial

.imageH
    height: 107px

.Modal2
    width: 400px

@media (min-width: 992px)
    .imageH
        height: 182px
    .Modal2
        width: 500px

@media (max-width: 575.98px)
    .Modal2
        width: 70%
        left: 15%