// 
// _buttons.scss
// 

button,a {
  outline: none !important;
}


// Rounded Buttons
.btn-rounded {
  border-radius: 30px;
}

.btn-dark, .btn-secondary{
  color: $gray-200 !important;
}

.btn-outline-light{
  color: $gray-900;
}

.img-up{
  width: 133px;
  padding: 10px;
}
.img-up:hover{
  background-color: rgba(149, 213, 255, 0.5);
  border-radius:5px;
  cursor: pointer;
}
.btn-send{
  background-color:rgb(21, 128, 61);
  border-radius:5px;
  color:white;
  font-size:14px;
  display:flex;
  width:fit-content;
  padding-left:8px;
  padding-right:8px;
  padding-bottom:2px;
  padding-top:2px;
  margin-bottom:2px;
  cursor:pointer;
}

.btn-send:hover{
  background-color:rgb(22, 163, 74);
}

.btn-delete{
  background-color:rgb(185, 28, 28);
  border-radius:5px;
  color:white;
  font-size:14px;
  display:flex;
  width:fit-content;
  padding-left:8px;
  padding-right:8px;
  padding-bottom:2px;
  padding-top:2px;
  margin-bottom:2px;
  cursor:pointer;
}

.btn-delete:hover{
  background-color:rgb(247, 42, 42);
}
