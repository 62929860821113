.reports {
  position: absolute;
  z-index: 5;
  background-color: #F5F7FB;
  width: 100%;
  height: calc(100% - 60px);
  overflow-y: hidden;
  top: 0px;
}

.reports iframe{
  width: 100%;
  height: 100%;
  border: none;
}

@media (min-width: 992px) {
  .reports {
    width: calc(100vw - 75px);
    height: 100vh;
    right: 0;
  }
}

.animate-spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
