.tableReports {
  color: #2f3542;
  width: 100%;
  text-align: center;
  border-collapse: collapse;
}

.tableReports__head {
  background-color: #57606f;
  color: white;

  display: block;
}

.tableReports__head__item {
  padding: 10px 0px;

  padding: 8px 10px;
  // border: 1px solid #ddd;
  // width: 10%;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tableReports__body {
  // max-height: 275px;
  // overflow-y: scroll;
  display: block;
  max-height: 200px;
  overflow-y: auto;
}

.tableReports__body__row {
  &:nth-child(even) {
    background-color: #f1f2f6;
  }
}

.tableReports__body__item {
  padding: 10px 0px;

  padding: 8px 10px;
  // border: 1px solid #ddd;
  // width: 10%;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tableReports__footer {
  background-color: #f1f2f6;
  border-top: 3px solid#2f3542;
  // color: white;

  display: block;
}
.tableReports__footer__item {
  font-weight: bold;
  padding: 10px 0px;

  padding: 8px 10px;
  // border: 1px solid #ddd;
  // width: 10%;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:nth-child(1) {
    text-transform: uppercase;
  }
}
