.status-template {
  position: absolute;
  z-index: 9;
  background-color: white;
  height: calc(100vh - 60px);
  padding: 40px;
  overflow-y: scroll;
  width: 100vw;
  margin-left: 0px;
  top: 0px;
}

@media (min-width: 992px) {
  .status-template {
    width: calc(100vw - 75px - 380px - 8px);
    position: absolute;
    z-index: 9;
    background-color: white;
    height: 100vh;
    margin-left: calc(75px + 380px + 8px);
    padding: 40px;
    overflow-y: scroll;
  }
}

.status-template__header__title {
  font-size: 24px;
}

.status-template__btnNavigation {
  display: flex;
  border: none;
  padding: 20px 15px;
  height: max-content;
  width: 100%;
  background-color: transparent;
  &:hover {
    background-color: #e6ebf5;
  }
}

.templates__item {
  margin-bottom: 10px;
  background-color: #e6ebf540;
  &:hover {
    background-color: #e6ebf5;
  }
  &:last-child {
    margin-bottom: 0px;
  }
}

.template-list-container {
  max-height: calc(100vh - 140px);

  @media (max-width: 992px) {
    max-height: calc(100vh - 157px);
    height: 100%;
  }
}

.status-template-tutorial {
  display: none;
  @media (min-width: 992px) {
    position: relative;
    display: flex;
    padding: 20px;
    margin: 20px;
    background-color: #10c99922;
    color: #10c999;
    > svg{
      margin-left: 2px;
    }
    > div {
      display: flex;
      flex-direction: column;
      margin-left: 20px;
      > p {
        margin-bottom: 0;
        font-size: 15px;
        font-weight: 600;
      }
      > a {
        margin-top: 5px;
        font-size: 13px;
        color: inherit;
        &:hover{
          color: inherit;
        }
      }
    }
  }
}