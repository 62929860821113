.listTemplate__group {
  display: flex;
  flex-direction: column;
  // align-items: flex-start;
  width: 100%;
  margin-bottom: 20px;
}

.itemListTemplate__angle {
  display: inline-block;
  background-color: transparent;
  border: none;
  transform: rotate(90deg);
  min-width: 25px;
  min-height: 25px;
  width: 25px;
  height: 25px;
}

.itemTemplate__section__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // padding: 10px 20px;
  padding: 10px 0px;
}

.itemTemplate__section__header__title {
}

.itemTeplate__section__content {
  background-color: #c8d6e540;
  padding: 10px;
  min-height: 80px;
  border-radius: 15px;
  > p {
    font-size: 15px;
    font-weight: 400;
  }
}

.itemTemplate__tagsContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: start;
}

.itemTemplate__tag {
  display: flex;
  font-size: 13px;
  border-radius: 10px;
  background-color: #00000015;
  padding: 7px 10px;
  margin-right: 10px;
}

.itemListTemplate__btn--edit {
  background-color: #e2e7f2;
  // height: 25px;
  // width: 25px;
  border: none;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-items: center;
  padding: 9px 10px;
  min-height: 21px;
  margin-left: 10px;
  // margin-right: 10px;
  > svg {
    width: 20px;
    height: 20px;
    > g {
      fill: #576574;
    }
  }
}

.itemListTemplate__btn--delete {
  background-color: #ff6b6b10;
  // height: 25px;
  // width: 25px;
  border: none;
  border-radius: 10px;
  min-height: 21px;
  display: flex;
  align-items: center;
  justify-items: center;
  padding: 9px 10px;
  margin-left: 10px;
  > svg {
    width: 20px;
    height: 20px;
    > g {
      > path {
        fill: #ff6b6b;
      }
    }
  }
}

.itemListTemplate__handlersContainer__top {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.itemListTemplate__handlersContainer {
  display: flex;
  align-items: center;
  width: 100%;
  > .btn-edit-tm {
    margin-top: 0px;
  }
  margin-top: 20px;
}

.messageSucces__itemListTemplate {
  margin-top: 20px;
  font-size: 14px;
  color: mediumpurple;
}

.itemListTemplate__sendLoading {
  background-color: #c8d6e540;
  color: #8395a790;
  width: 100%;
  height: 40px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.segments__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.segments__title {
  font-size: 18px;
}

.segments__subTitle {
  margin-bottom: 0px;
}

.segments__modalBody {
  padding-bottom: 70px;
}

.segment-list-container {
  max-height: calc(100vh - 140px);

  @media (max-width: 992px) {
    max-height: calc(100vh - 131px);
    height: 100%;
  }
}