// 
// _dropdown.scss
// 

.dropdown-menu {
  box-shadow: $box-shadow;
  animation-name: DropDownSlide;
  animation-duration: .3s;
  animation-fill-mode: both;
  margin: 0;
  position: absolute;
  z-index: 1000;

  &.show {
    top: 100%!important;
  }
}

.dropdown-menu-right {
  right: 0!important;
  left: auto!important;
}

.dropdown-menu[x-placement^=right],.dropdown-menu[x-placement^=top],.dropdown-menu[x-placement^=left]{
  top: auto !important;
  animation: none !important;
}

@keyframes DropDownSlide {
  100% {
      -webkit-transform: translateY(0);
      transform: translateY(0)
  }
  0% {
      -webkit-transform: translateY(10px);
      transform: translateY(10px)
  }
}

@media (min-width: 600px) {
  .dropdown-menu-lg {
    width: 320px;
  }

  .dropdown-menu-md {
    width: 240px;
  }
}

.dropdown-divider{
  border-top-color: $border-color;
}

.dropdown-custom{
  background-color: white;
  border-radius: 5px;
  color: #495057;
}

.dropdown-custom:hover{
  background-color: rgb(185, 185, 185);
}

.dropdown-custom:focus{
  box-shadow: none;
}